import { InterestBehaviourTemplateActionEvents } from "../actions/interest-behaviour.action.enums";
import { InterestBehaviourTemplatesState } from "./types";

const initState: InterestBehaviourTemplatesState = {
  organisations: [],
  interestBehaviourTemplates: [],
  loader: false,
  error: null,
};

export default function interestBehaviourTemplateReducer(
  state = initState,
  action: any
): InterestBehaviourTemplatesState {
  switch (action.type) {
    case InterestBehaviourTemplateActionEvents.GET_ALL_ORGANISATIONS: {
      return {
        ...state,
        organisations: action.payload,
      };
    }

    case InterestBehaviourTemplateActionEvents.GET_ALL_INTEREST_BEHAVIOUR_TEMPLATES: {
      return {
        ...state,
        interestBehaviourTemplates: action.payload,
      };
    }

    case InterestBehaviourTemplateActionEvents.ADD_INTEREST_BEHAVIOUR_TEMPLATE: {
      return {
        ...state,
        interestBehaviourTemplates: action.payload,
      };
    }

    case InterestBehaviourTemplateActionEvents.LOADER: {
      return { ...state, loader: action.bool };
    }

    default: {
      break;
    }
  }
  return state;
}
